@use 'styles/includes' as *;

.CustomerServiceLinkAccordion {
    $root: &;
    padding-left: 2rem;

    @include media(m) {
        padding-left: 4rem;
    }

    &--NoPadding {
        padding-left: 0;
    }

    &__Button {
        padding: 1rem 0 1rem;
        width: 100%;
        font-family: $font-family-bold;
        font-size: 1.6rem;
        font-weight: $font-weight-bold;
        line-height: normal;
        text-align: left;
        border: 0;
        background-color: transparent;
        border-bottom: 1px solid $black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        @include media(m) {
            padding: 2rem 0 2rem;
            font-size: 3rem;
        }

        #{$root}--Expanded & {
            border-bottom: 3px solid $black;

            @include media(m) {
                border-bottom: 4px solid $black;
            }
        }
    }

    &__Icon {
        width: 14px;

        @include media(m) {
            width: 20px;
        }

        #{$root}--Expanded & {
            transform: rotate(180deg);
        }
    }

    &__Panel {
        display: grid;
        grid-template-rows: 0fr;
        // transition: grid-template-rows 150ms ease-out 0s;

        #{$root}--Expanded & {
            grid-template-rows: 1fr;
        }
    }

    &__Content {
        overflow: hidden;
        visibility: hidden;

        #{$root}--Expanded & {
            overflow: visible;
            visibility: visible;
        }
    }

    &__PageLink {
        padding: 0 0 1rem;
        margin: 2rem 0 0 2rem;
        display: block;
        border-bottom: 1px solid $black;
        font-family: $font-family-bold;
        font-size: 1.4rem;
        font-weight: $font-weight-bold;
        line-height: normal;

        @include media(m) {
            padding: 2rem 0 2rem;
            margin: 0 0 0 4rem;
            font-size: 2rem;
        }
    }
}
