@use 'styles/includes' as *;

.CustomerServiceRelated {
    $root: &;
    @extend %container;
    margin-top: 4rem;
    margin-bottom: 4rem;
    font-family: $font-family-bold;
    font-weight: $font-weight-bold;
    color: $black;

    &__Title {
        margin: 0 0 1rem;
        font-size: 2rem;
        line-height: normal;

        @include media(m) {
            margin: 0 0 2rem;
            font-size: 3rem;
        }
    }

    &__List {
        display: grid;
        gap: 1.6rem;

        @include media(m) {
            gap: 1.3rem;
        }
    }

    &__Link {
        font-size: 1.6rem;
        line-height: normal;
        text-decoration: underline;
        text-decoration-thickness: 0.1rem;
        text-underline-offset: 0.3rem;

        @include media(m) {
            font-size: 2rem;
        }
    }
}
