@use 'styles/includes' as *;

.HeroArticlePageVideo {
    $root: &;
    background-color: $black;
    color: $white;

    &__Container {
        @extend %container;
        padding-top: 27.7rem;
        padding-bottom: 8rem;
    }

    &__Title {
        font-family: $font-family-bold;
        font-size: 4.8rem;
        font-weight: $font-weight-bold;
        line-height: 100%;
        letter-spacing: -1.44px;
    }

    &__Text {
        @extend %p;
        margin: 1.6rem 0 0;
    }

    &__Video {
        position: relative;
    }

    &__Image {
        width: 100%;
        height: 100%;
        background-color: $black;
        object-fit: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        inset: 0;
        z-index: 2;
    }

    &__PlayButton {
        color: $white;
        background-color: transparent;
        border: 0;
        position: absolute;
        z-index: 2;
    }

    &__Embed {
        width: 100%;
        height: 100%;

        iframe {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            inset: 0;
        }
    }

    &__Controls {
        position: absolute;
        bottom: 0.8rem;
        left: 0.8rem;
        z-index: 1;

        @include media(l) {
            bottom: 2rem;
            left: 2rem;
        }
    }

    &__Button {
        padding: 0.8rem;
        color: $black;
        background-color: $white;
        border: 0;

        @include media(l) {
            padding: 2rem;
        }
    }

    &__Breadcrumbs {
        margin: 0 0 0.5rem;
        font-family: $font-family-regular;
        font-size: 1.4rem;
        line-height: normal;
        color: $grey-60;

        @include media(l) {
            font-size: 2rem;
        }
    }

    &__PageLink {
        &::after {
            content: ' / ';
        }
    }

    &__CurrentPage {
        color: $white;
    }
}
