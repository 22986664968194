@use 'styles/includes' as *;

.HeroStartPage {
    $root: &;
    @extend %container;

    padding: 0;
    position: relative;
    overflow: hidden;
    background-color: $black;

    @include media-max(m) {
        aspect-ratio: 9/16;
    }

    @include media(xxl) {
        padding: 16rem 3rem 0 3rem;
        height: auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        background-color: transparent;
    }

    @media (min-width: 544px) and (max-height: 1170px) {
        height: 100vh;
    }

    &--Video {
        @include media(m) {
            aspect-ratio: 11/16;
        }

        @include media(l) {
            aspect-ratio: 13/16;
        }

        @include media(xxl) {
            height: auto;
            aspect-ratio: unset;
        }
    }

    &__Container {
        position: absolute;
        z-index: 2;
        inset: 0;

        @include media(xxl) {
            position: relative;
            width: 100%;
            height: 100%;
        }
    }

    &__MediaContainer {
        position: relative;
        height: 100%;

        @include media(xxl) {
            aspect-ratio: 440/550;
        }
    }

    &__ImageContainer {
        height: 100%;

        @include media(xxl) {
            width: 100%;
            position: absolute;
            top: 0;
        }
    }

    &__Image {
        position: absolute;
        width: 100%;
        height: 100%;

        @include media(xxl) {
            height: 100%;
            aspect-ratio: 440/550;

            &::after {
                content: '';
                position: absolute;
                inset: 0;
                border-top: 0.2rem solid $black;
                border-left: 0.2rem solid $black;
                border-right: 0.2rem solid $black;
                z-index: 1;
            }
        }
    }

    &__VideoContainer {
        overflow: hidden;
        position: absolute;
        inset: 0;
        z-index: 1;
        opacity: 0;
        transition: opacity $transition-linear;

        @include media(xxl) {
            position: relative;
            height: 100%;
            width: 100%;

            &::after {
                content: '';
                position: absolute;
                inset: 0;
                border-top: 0.2rem solid $black;
                border-left: 0.2rem solid $black;
                border-right: 0.2rem solid $black;
                z-index: 1;
            }
        }

        #{$root}--VideoStarted & {
            opacity: 1;
        }
    }

    &__Video {
        aspect-ratio: 9/16;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        iframe {
            width: calc(100% + 0.2rem);
            height: calc(100% + 0.2rem);
            position: absolute;
            inset: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__Content {
        padding: 2rem 2rem 4rem 2rem;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;

        @include media(s) {
            padding: 2rem 3rem 4rem 3rem;
        }

        @include media(xxl) {
            padding: 3rem 0;
            justify-content: center;
        }
    }

    &__Title {
        margin: 0 0 2rem;
        max-width: 67rem;
        font-family: $font-family-bold;
        font-size: 4.6rem;
        font-weight: $font-weight-bold;
        letter-spacing: -0.12rem;
        line-height: 100%;
        color: $white;
        overflow-wrap: break-word;
        word-break: break-word;

        @include media(s) {
            font-size: 6.8rem;
        }

        @include media(xxl) {
            color: $black;
            font-size: 8rem;
        }
    }

    &__Text {
        font-family: $font-family-bold;
        font-weight: $font-weight-bold;
        font-size: 1.4rem;
        line-height: 100%;
        color: $white;
        overflow-wrap: break-word;
        word-break: break-word;

        @include media(xxl) {
            font-size: 1.6rem;
            color: $black;
        }
    }

    &__Button {
        margin: 2rem 0 0 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        @include media(m) {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
}
