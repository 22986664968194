@use 'styles/includes' as *;

.Pagination {
    $root: &;

    margin: 2rem 0 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;

    &,
    &__Link {
        font-size: 2.4rem;
        line-height: 100%;
    }

    @include media(m) {
        justify-content: center;
    }

    &__Previous,
    &__Next {
        &--Disabled {
            color: $grey-60;
        }
    }

    &__Link {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
        background-color: transparent;
        border: none;
        text-decoration-color: transparent;
        text-decoration-thickness: 0.1rem;
        transition: text-decoration-color $transition-ease-out-expo;
        cursor: pointer;

        @media (hover: hover) {
            &:hover {
                text-decoration: underline;
                text-decoration-color: $black;
                text-decoration-thickness: 0.1rem;
                text-underline-offset: 0.4rem;
            }
        }
    }

    &__NumPages {
        color: $grey-60;
    }

    &__Text {
        display: none;

        @include media(m) {
            display: block;
        }
    }
}
