@use 'styles/includes' as *;

.HeroNewsHub {
    $root: &;

    padding: 12rem 0 6rem;
    background-color: $black;

    &__Container {
        @extend %container;
    }

    &__Title {
        font-family: $font-family-bold;
        font-size: 4.8rem;
        font-weight: $font-weight-bold;
        line-height: 100%;
        letter-spacing: -1.44px;
        color: $white;
    }
}
