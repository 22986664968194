@use 'styles/includes' as *;

.NotFoundPage {
    padding-top: 12rem;
    margin-bottom: 18rem;
    @extend %container;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: $font-family-regular;
    color: $black;

    &__Title {
        font-size: 3.6rem;
        margin-bottom: 2rem;
        line-height: 100%;
    }

    &__Text {
        font-size: 1.8rem;
        line-height: 100%;
        color: $grey-60;

        a {
            color: $black;
        }
    }
}
