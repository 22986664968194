@use 'styles/includes' as *;

.NewsHubLandingPage {
    $root: &;

    &__Container {
        @extend %container;
        margin-top: 6rem;
        margin-bottom: 6rem;

        @include media(m) {
            margin-bottom: 10rem;
        }

        @include media-max(l) {
            padding: 0;
        }
    }

    &__Grid {
        display: grid;

        @include media(l) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
