@use 'styles/includes' as *;

.Newsletter {
    $root: &;

    @extend %container;
    background-color: $black;

    &__Container {
        padding: 2rem;

        @include media(m) {
            padding: 4rem;
        }
    }

    &__Content {
        border: 0.1rem dashed $white;
        padding: 2rem;

        @include media(m) {
            padding: 4rem;
        }
    }

    &__InputField {
        margin-bottom: 2rem;
        width: 100%;
        font-family: $font-family-bold;
        font-size: 1.8rem;
        font-weight: $font-weight-bold;
        line-height: 100%;
        text-align: center;
        color: $white;
        background-color: $black;
        border: 0;

        &::placeholder {
            color: $grey-60;
        }

        @include media(m) {
            margin-bottom: 0;
            text-align: left;
            font-size: 3.5rem;
        }
    }

    &__Button {
        padding: 2rem 3.2rem;
        width: 100%;
        font-family: $font-family-bold;
        font-weight: $font-weight-bold;
        font-size: 1.6rem;
        line-height: 100%;
        text-align: center;
        color: $black;
        background-color: $white;
        border: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 0.8rem;
        position: relative;
        z-index: 0;
        transition: color $transition-ease-out-expo;

        @include media(m) {
            padding: 2.2rem 3.2rem;
            width: auto;
            font-size: 2rem;
        }

        &::after {
            content: '';
            width: 0;
            height: 100%;
            background-color: $violet;
            position: absolute;
            inset: 0;
            z-index: -1;
            transition: width $transition-ease-out-expo;
        }

        @media (hover: hover) {
            &:hover {
                color: $black;

                &::after {
                    width: 100%;
                }
            }
        }

        span {
            width: 1.6rem;
            height: 1.6rem;

            @include media(m) {
                width: 2rem;
                height: 2rem;
            }
        }
    }
}
